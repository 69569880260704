<template>
  <div class="w-100 d-flex flex-column">
    <base-tool-bar title="Informations" subtitle="Informations générales"></base-tool-bar>
    <base-form
      shadow
      class="mr-md-3"
      :loading="loading"
      :deleteBtn="true"
      @click="updateAffaire"
      @delete="deleteAffaire"
    >
      <base-form-group title="Affaire" dense icon="briefcase">
        <base-form-row row>
          <base-select-modal
            ref="comptesmanagerselect"
            name="comptesmanager"
            title="Gestion des comptes client"
            placeholder="Compte"
            empty="Sélectionner un compte"
            :data="affaire_comptes"
            :description="compteDescription"
            :feedback="feedback.affaire_compte_id"
          >
            <comptes-manager @select="selectCompte"></comptes-manager>
          </base-select-modal>
          <base-select
            inputText="Etat"
            v-model.number="affaire_etat_id"
            :options="data.etats"
            :errors="feedback.affaire_etat_id"
          ></base-select>
        </base-form-row>
        <base-form-row row v-if="affaire_compte_id > 0">
          <base-select-modal
            ref="clientsmanagerselect"
            name="clientsmanager"
            title="Gestion des clients"
            placeholder="Clients"
            empty="Sélectionner un client"
            :data="affaire_clients"
            :description="clientDescription"
            :feedback="feedback.affaire_client_id"
          >
            <clients-manager @select="selectClient" :compte_id="affaire_compte_id"></clients-manager>
          </base-select-modal>

          <base-select
            inputText="Activité"
            v-model.number="affaire_activite_id"
            :options="data.activites"
            :errors="feedback.affaire_activite_id"
          ></base-select>
        </base-form-row>
        <base-form-row row>
          <base-input v-model="affaire_libelle" inputText="Libellé" :errors="feedback.affaire_libelle"></base-input>
        </base-form-row>
        <base-form-row row>
          <user-selector
            v-model.number="affaire_pilote_id"
            :text="'Pilote de l\'affaire'"
            @input="userSelected"
          ></user-selector>
          <div class="d-flex align-items-center w-100">
            <Checkbox
              class="mb-3"
              v-model="affaire_enjeux_dosi"
              text="Cochez s'il y a exposition(s) des salariés aux rayonnements ionisants"
              :errors="feedback.affaire_enjeux_dosi"
              :iconActive="true"
            ></Checkbox>
          </div>
        </base-form-row>
        <base-form-row row class="">
          <base-input-area v-model="affaire_details" text="Commentaire(s)"></base-input-area>
        </base-form-row>
        <base-form-row row class="mb-4">
          <Checkbox
            v-if="affaire_etat_id == 3"
            v-model="affaire_facturee"
            text="Affaire totalement facturée"
            :errors="feedback.affaire_facturee"
          ></Checkbox>
        </base-form-row>
        <base-form-row class="rounded p-2 p-md-4" :style="{ backgroundColor: bg }">
          <h5 class>Site client</h5>
          <div class="d-flex">
            <img src="/images/map.svg" style="max-height: 50px" class="mr-3" alt />
            <base-input-area
              v-model="affaire_site_client"
              text="Site client"
              :errors="feedback.affaire_site_client"
            ></base-input-area>
          </div>
          <iframe
            v-if="affaire_site_client"
            width="100%"
            height="350"
            frameborder="0"
            style="border: 0"
            :src="
              'https://www.google.com/maps/embed/v1/place?q=' +
              affaire_site_client +
              '&key=AIzaSyBCUAhZ47TnVMdJJmjczhb_IN7CLttqWFo'
            "
          ></iframe>
        </base-form-row>
      </base-form-group>
    </base-form>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import BaseInput from "@/components/bases/Input.vue";
import BaseSelect from "@/components/bases/Select.vue";
import BaseSelectModal from "@/components/bases/SelectModal.vue";
import BaseInputArea from "@/components/bases/InputArea.vue";
import BaseForm from "@/components/bases/Form.vue";
import BaseFormGroup from "@/components/bases/FormGroup.vue";
import BaseFormRow from "@/components/bases/FormRow.vue";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import Checkbox from "@/components/bases/Checkbox.vue";
import ComptesManager from "@/components/comptes/Manager.vue";
import ClientsManager from "@/components/clients/Manager.vue";
import UserSelector from "@/components/application/UserSelector.vue";

export default {
  components: {
    BaseInput,
    BaseSelect,
    BaseSelectModal,
    BaseInputArea,
    Checkbox,
    BaseForm,
    BaseFormGroup,
    BaseFormRow,
    BaseToolBar,
    ComptesManager,
    ClientsManager,
    UserSelector,
  },
  data() {
    return {
      loading: false,
      lists: {},
      feedback: {},
    };
  },
  props: {
    data: {
      Type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions({
      update: "affaires/updateAffaire",
      delete: "affaires/deleteAffaire",
      _autorize: "affaires/autorizeUser",
      _unautorize: "affaires/unautorizeUser",
    }),
    updateAffaire: function () {
      this.loading = true;
      this.feedback = {};
      this.update()
        .catch((error) => this.errHandler(error))
        .finally(() => (this.loading = false));
    },
    deleteAffaire: function () {
      if (!window.confirm("Confirmer la suppression de l'élément ?")) return null;
      this.loading = true;
      this.feedback = {};
      this.delete()
        .then(() => this.$router.push("/crm/dashboard"))
        .catch((error) => this.errHandler(error))
        .finally(() => (this.loading = false));
    },
    errHandler: function (error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    },
    selectCompte: function (compte) {
      this.$store.commit("affaires/setCompte", compte);
      this.$refs.comptesmanagerselect.hideModal();
    },
    selectClient: function (client) {
      this.$store.commit("affaires/setClient", client);
      this.$refs.clientsmanagerselect.hideModal();
    },
    userSelected: function (id) {
      this.autorize({ id: id });
      this.updateAffaire();
    },
    autorize: function (data) {
      this.feedback = {};
      this.loading = true;
      this._autorize({
        user_id: data.id,
        affaire_id: this.$route.params.id,
      })
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
    unautorize: function (data) {
      this.feedback = {};
      this.loading = true;
      this._unautorize({
        user_id: data.id,
        affaire_id: this.$route.params.id,
      })
        //.catch(error => (this.feedback = errHandler(error)))
        .finally(() => (this.loading = false));
    },
  },
  computed: {
    ...mapFields("affaires", [
      "affaire.affaire_compte_id",
      "affaire.affaire_pilote_id",
      "affaire.affaire_etat_id",
      "affaire.affaire_client_id",
      "affaire.affaire_site_client",
      "affaire.affaire_activite_id",
      "affaire.affaire_libelle",
      "affaire.affaire_date_debut",
      "affaire.affaire_date_fin",
      "affaire.affaire_enjeux_dosi",
      "affaire.affaire_budget_prev",
      "affaire.affaire_montant_facture",
      "affaire.affaire_details",
      "affaire.affaire_comptes",
      "affaire.affaire_clients",
      "affaire.affaire_facturee",
    ]),
    compteDescription() {
      if (this.affaire_comptes) {
        return this.affaire_comptes.description;
      }
      return "";
    },
    clientDescription() {
      if (this.affaire_clients) {
        return this.affaire_clients.client_nom + " " + this.affaire_clients.client_prenom;
      }
      return "";
    },
    bg() {
      return this.$store.getters["colors/colors"].color2;
    },
  },
};
</script>
